.centered {
  justify-content: center;
  align-items: center;
}

.display-6 {
  user-select: none;
}

.card-under {
  border-style: solid;
  border-color: black;
  border-top: 0px;
  border-width: 1px
}

.input-group-text {
  background-color: black;
  color: whitesmoke;
  border-color: grey;
}

.table {
  margin-bottom: 0;
}

thead {
  user-select: none;
}

.btn-purple {
  background-color: #7F00FF;
  color: white;
}

.btn-purple:hover {
  background-color: #6600CC;
  color: white;
}

.btn {
  border-color: grey;
  border-width: 1px;
}